<template>
  <div class="layout-default">
    <BrandHeader :fluid="true" />
    <div class="body-container">
      <div class="layout-main-holder">
        <div class="content-holder">
          <VueWrapperNarrow class="wrapper-narrow">
            <VueHeadline level="2" weightLevel="3">{{ item.header }}</VueHeadline>
            <VueText
              class="contract-body"
              weightLevel="2"
              sizeLevel="4"
              color="grey-40"
              v-html="item.text"
            ></VueText>
          </VueWrapperNarrow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import BrandHeader from '@/components/brand/BrandHeader/BrandHeader';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'Ayd',
  components: {
    BrandHeader,
    VueText,
    VueWrapperNarrow,
    VueHeadline,
  },
  data() {
    return {
      item: {
        header: 'Gizlilik Bildirimi',
        text: '',
      },
    };
  },
  created() {
    StaticContent.getPublicStaticContent('VeriPolitikalari').then(res => {
      if (res.data.Data) {
        this.item.text = res.data.Data.content;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  display: flex;
  flex-direction: row;
  min-width: $body-width;

  .layout-main-holder {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .content-holder {
      min-height: calc(100vh - 80px);
      padding-bottom: palette-space-level(50);
    }
  }
}

.layout-default {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.wrapper-narrow {
  padding-top: palette-space-level('60');
}

.contract-body {
  line-height: 1.71;
  padding-top: palette-space-level('20');
  font-weight: 300;
  ul {
    padding-left: 40px;
  }
}
</style>
