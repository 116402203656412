<template>
  <div class="header-container">
    <div class="header-left" :class="[isPartner ? 'pmi-background' : 'default-background']">
      <div class="content-layout-fixer">
        <div class="head-wrapper">
          <template v-if="!refreshHomeScreenEnabled">
            <router-link :to="'/'">
              <img src="@/assets/logos/main-logo.png" alt="main-logo" class="logo" />
            </router-link>
          </template>
          <template v-else>
            <img
              @click="refreshHome"
              src="@/assets/logos/main-logo.png"
              alt="main-logo"
              class="logo"
            />
          </template>
        </div>
      </div>
      <ReportButton v-if="isReportBtnVisible" />
      <a v-if="!fluid" class="menu-button" href="#" @click.prevent="setMenuStatus(!getMenuStatus)">
        <img src="@/assets/button/menu.png" alt="" class="btn-menu" />
        <span>Menü</span>
      </a>
    </div>
    <div v-if="!fluid" @click="goProfile" class="header-right">
      <div v-if="getUserModel" class="profile-avatar">
        <img :src="userPhoto" alt="user-avatar" />
      </div>
      <VueText
        v-if="getUserModel && !getUserModel.isImageRecognitionUser"
        sizeLevel="6"
        weightLevel="3"
        class="user-name"
        >Merhaba {{ getUserModel ? getUserModel.name : '' }}</VueText
      >
      <NotificationCard />
    </div>
  </div>
</template>
<script>
import VueText from 'VueText';
import { mapGetters } from 'vuex';
import RoutesSecure from '@/router/routes/secure/RoutesSettings';
import ReportButton from '@/components/brand/ReportButton.vue';
import StorageProps from '@/mixins/storageProps.js';
import NotificationCard from '@/components/brand/SideBar/NotificationCard';

export default {
  name: 'BrandHeader',
  mixins: [StorageProps],
  props: {
    fluid: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    VueText,
    ReportButton,
    NotificationCard,
  },
  data() {
    return {
      refreshHomeScreenEnabled: false,
    };
  },
  watch: {
    $route(to) {
      this.refreshHomeScreenEnabled = to.name === 'feed';
    },
  },
  mounted() {
    this.refreshHomeScreenEnabled = this.$route.name === 'feed';
  },
  computed: {
    ...mapGetters('app', ['getMenuStatus']),
    ...mapGetters('auth', ['getUserModel']),
    userPhoto() {
      return this.getUserModel && this.getUserModel.picture && this.getUserModel.picture.length
        ? this.getUserModel.picture
        : process.env.VUE_APP_USER_PHOTO_PLACEHOLDER;
    },
    isReportBtnVisible() {
      return (
        this.$route.path.includes('image-recognition') &&
        !this.$route.path.includes('report-problem')
      );
    },
  },
  methods: {
    setMenuStatus(status) {
      this.$store.dispatch('app/setMenuStatus', status);
    },
    goProfile() {
      if (this.$route.name !== RoutesSecure.Profile.path)
        this.$router.push({
          name: RoutesSecure.Profile.path,
        });
    },
    refreshHome() {
      this.$store.dispatch('app/setCategories', []);
      this.$store.dispatch('app/setContentTypes', []);
      this.$store.dispatch('app/setLastPosition', 0);
      this.$emit('refresh-home-screen', true);
    },
  },
};
</script>
<style lang="scss" scoped>
.pmi-background {
  background: #c60000;
}
.default-background {
  @include bg-linear-gradient(
    to left,
    palette-color-level('fushia', '20') 0%,
    palette-color-level('yellow', '30') 100%
  );
}

.header-container {
  height: $header-height;
  width: 100%;
  min-width: $body-width;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9;
  background: palette-color-level('white', '100');

  .header-left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .head-wrapper {
      padding: 0 palette-space-level(20);
      width: 100%;
      display: flex;
      /* @media screen and (min-width: 1388px) {
        padding-left: 10px;
      } */
    }

    .menu-button {
      position: absolute;
      right: palette-space-level('20');
      top: 22px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-decoration: none;

      span {
        color: #fff;
        margin-top: 4px;
      }
    }
    img,
    a {
      height: 26px;
      cursor: pointer;
    }

    // background: #c60000;

    /* @include bg-linear-gradient(
      to left,
      palette-color-level('fushia', '20') 0%,
      palette-color-level('yellow', '30') 100%
    ); */
  }
  .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: $sidebar-width;
    right: 0;
    padding: 0 palette-space-level(20);
    cursor: pointer;
    img {
      width: 55px;
      height: 55px;
    }
    .user-name {
      padding-left: palette-space-level(20);
    }
    .profile-avatar {
      border-radius: 50%;
      overflow: hidden;
      height: 55px;
    }
  }
}
</style>
